/*
 * @Description: 工单模块----添加任务项
 * @Author: ChenXueLin
 * @Date: 2021-11-17 09:48:14
 * @LastEditTime: 2022-10-19 13:51:23
 * @LastEditors: ChenXueLin
 */
import {
  getCorpMsg,
  getContactList,
  getSceneEquip,
  checkCreateTask,
  getAddressData,
  getServiceBasicInfo
} from "@/api";
import setting from "@/setting.js";
let { SUBMIT_BUTTON_ID } = setting;
import goBack from "@/mixins/goBack";
import { printError } from "@/utils/util";

export default {
  data() {
    return {
      addContactVisible: false,
      nowDate: new Date().setHours(new Date().getHours() + 4),
      SUBMIT_BUTTON_ID,
      corpList: [], //客户名称下拉框
      contactList: [], //联系人下拉框数据
      pickerOptions1: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp < nowTime;
        }
      },
      /****添加场景*******/
      selectSceneDialog: false,
      columnData: [
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneTypeName",
          display: true,
          fieldLabel: "场景类型",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "安装组合",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "virtualTypeName",
          display: true,
          fieldLabel: "商品类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //任务项表头
      tableData: [], //任务项表数据
      deleteData: [], //要删除的任务项
      equipList: [], //从服务详情页面跳转带过来的设备id
      queryId: "", //带过来的corpId
      queryTableData: [], //通过equipId查询出来的数据
      unSceneName: [], //不能提交的场景
      addressList: []
    };
  },
  mixins: [goBack],
  components: {},
  computed: {},
  watch: {
    // 监听客户名称更改
    "baseInfoForm.corpId": {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.getContactList(val);
        }
        if (val != oldVal && !this.queryId) {
          this.tableData = [];
          this.inspectionData = [];
          this.baseInfoForm.linkmanrpid = "";
          this.baseInfoForm.contactPhone = "";
          this.baseInfoForm.cityName = "";
          this.baseInfoForm.insAddress = "";
        }
      }
    }
  },
  created() {
    this.initForm();
    this.loadAddressData();
  },
  activated() {
    this.handleRefreshUpdatePage();
    // this.initForm();
  },
  methods: {
    // 初始化表单
    async initForm() {
      //needinit是否直接请求
      let hasParamsRoute = this.getHasParamsRoute(); // base Mixin的方法
      if (hasParamsRoute.params.queryType) {
        this.equipList = _.cloneDeep(
          JSON.parse(hasParamsRoute.params.equipList)
        );
        console.log(JSON.parse(hasParamsRoute.params.equipList));
        this.baseInfoForm.quesReason = hasParamsRoute.params.quesReason;
        await this.getServiceBasicInfoReq(hasParamsRoute.params.serviceId);
        if (hasParamsRoute.params.isStop) {
          this.selectForm.isStop = hasParamsRoute.params.isStop;
        }
      } else {
        this.$nextTick(() => {
          this.$refs.baseInfoForm.resetFields();
        });
        this.queryId = "";
      }
    },
    //获取基本信息
    async getServiceBasicInfoReq(serviceId) {
      try {
        this.loading = true;
        let res = await getServiceBasicInfo({
          serviceId
        });
        //基本信息
        let queryData = this.getFreezeResponse(res, {
          path: "data"
        });
        console.log(queryData);
        this.queryId = queryData.corpId * 1;
        this.loadCorpData(queryData.corpName);
        this.baseInfoForm = { ...this.baseInfoForm, ...queryData };
        this.baseInfoForm.corpId = queryData.corpId * 1;
        this.baseInfoForm.contactName = queryData.contactName;
        this.baseInfoForm.contactPhone = queryData.contactPhone;
        this.baseInfoForm.linkmanrpid = queryData.contactId
          ? queryData.contactId
          : "";
        this.baseInfoForm.serverTaskId = queryData.serviceId;
        this.baseInfoForm.defaultCity =
          this.baseInfoForm.provinceName +
          this.baseInfoForm.cityName +
          this.baseInfoForm.insCountyName +
          this.baseInfoForm.streetName;
        this.baseInfoForm.province = queryData.provinceId;
        this.baseInfoForm.city = queryData.cityId;
        this.baseInfoForm.insCounty = queryData.insCountyId;
        this.baseInfoForm.street = queryData.streetId;
        this.loadAddressData(this.baseInfoForm.defaultCity);
        delete this.baseInfoForm.expectTimeStamp;
        delete this.baseInfoForm.expectTime;
        delete this.baseInfoForm.expectTimeStr;
        this.tableData = [];
        if (this.equipList.length) {
          this.queryHasEquipList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //选择联系人
    handleContact() {
      this.handleClear();
      let concatInfo = this.contactList.find(item => {
        return item.contactId == this.baseInfoForm.linkmanrpid;
      });
      this.baseInfoForm.contactPhone = concatInfo.phone1
        ? concatInfo.phone1
        : "";
      if (concatInfo.addressList.length && concatInfo.addressList[0].areaName) {
        //省
        this.baseInfoForm.provinceName = concatInfo.addressList[0].provinceName;
        this.baseInfoForm.province = concatInfo.addressList[0].provinceId;
        //市
        this.baseInfoForm.cityName = concatInfo.addressList[0].cityName;
        this.baseInfoForm.city = concatInfo.addressList[0].cityId;
        //区
        this.baseInfoForm.insCountyName = concatInfo.addressList[0].areaName;
        this.baseInfoForm.insCounty = concatInfo.addressList[0].areaId;
        //街道
        this.baseInfoForm.streetName = concatInfo.addressList[0].streetName;
        this.baseInfoForm.street = concatInfo.addressList[0].streetId;
        //详细地址
        this.baseInfoForm.insAddress = concatInfo.addressList[0].address;
        //默认地址
        this.baseInfoForm.defaultCity =
          concatInfo.addressList[0].provinceName +
          concatInfo.addressList[0].cityName +
          concatInfo.addressList[0].areaName +
          concatInfo.addressList[0].streetName;
        this.loadAddressData(this.baseInfoForm.defaultCity);
      }
    },
    //清空联系人
    handleClear() {
      this.baseInfoForm.contactPhone = "";
      this.baseInfoForm.provinceName = "";
      this.baseInfoForm.province = "";
      //市
      this.baseInfoForm.cityName = "";
      this.baseInfoForm.city = "";
      //区
      this.baseInfoForm.insCountyName = "";
      this.baseInfoForm.insCounty = "";
      //街道
      this.baseInfoForm.streetName = "";
      this.baseInfoForm.street = "";
      //详细地址
      this.baseInfoForm.insAddress = "";
      this.baseInfoForm.defaultCity = "";
    },
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //加载客户名称
    async loadCorpData(val) {
      try {
        let res = await getCorpMsg({
          corpId: "",
          pageNum: 1,
          pageSize: 200,
          isManageEmp: false,
          corpName: val
        });
        //获取客户名称
        this.corpList = this.getFreezeResponse(res, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      }
    },
    //加载地址列表
    async loadAddressData(val = "") {
      try {
        let res = await getAddressData({
          addressName: val
        });
        //获取地址下拉框
        this.addressList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    handleLoadAddressFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.defaultCity = "";
        this.baseInfoForm.province = "";
        this.baseInfoForm.provinceName = "";
        this.baseInfoForm.city = "";
        this.baseInfoForm.cityName = "";
        this.baseInfoForm.insCounty = "";
        this.baseInfoForm.insCountyName = "";
        this.baseInfoForm.street = "";
        this.baseInfoForm.streetName = "";
        this.loadAddressData(val);
      }
    }, 300),
    //获取联系人下拉框
    async getContactList() {
      try {
        let res = await getContactList({ id: this.baseInfoForm.corpId });
        //客户名称下拉框
        this.contactList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //处理客户名称下拉框
    handleChange() {
      this.baseInfoForm.corpName = this.corpList.find(item => {
        return item.corpId == this.baseInfoForm.corpId;
      }).corpName;
    },
    //添加联系人之后更新下拉框
    updateList() {
      this.getContactList();
    },
    // 处理城市选择
    handleCitySelect(select, node) {
      let val = _.cloneDeep(node);
      this.baseInfoForm.province = val.provinceCode;
      this.baseInfoForm.provinceName = val.provinceName;
      this.baseInfoForm.city = val.cityCode;
      this.baseInfoForm.cityName = val.cityName;
      this.baseInfoForm.insCounty = val.countyCode;
      this.baseInfoForm.insCountyName = val.countyName;
      this.baseInfoForm.streetId = val.streetCode;
      this.baseInfoForm.street = val.streetCode;
      this.baseInfoForm.streetName = val.streetName;
      this.baseInfoForm.defaultCity = val.fullName;
    },
    //添加联系人
    addConcat() {
      if (!this.baseInfoForm.corpId) {
        this.$message.warning("请先选择客户名称");
        return;
      }
      this.addContactVisible = true;
    },
    /******添加任务项*********/
    //添加任务项
    addScene() {
      if (this.baseInfoForm.corpId) {
        this.selectSceneDialog = true;
      } else {
        this.$message.warning("请先选择客户名称");
        return;
      }
    },
    //选择场景数据
    handleSelectionChange(val) {
      console.log(val, "===");
      this.deleteData = val;
    },
    //添加的场景列表
    getData(data) {
      data.map((item, index) => {
        item.problemType = ""; //问题类型
        item.quesName = ""; //问题名称
        item.newSceneName = ""; //新场景名称
        item.newSceneType = ""; //新场景类型
        item.requestDate = ""; //开通时间/报停时间
        item.stopReason = index > 0 ? "同上" : ""; //报停原因
        item.needToRemove = index > 0 ? 2 : 0; //是否拆机
        //判断数组中是否已有选中的数据
        if (
          this.tableData.some(
            v =>
              v.thirdClassId == item.thirdClassId &&
              v.sceneName != "未知场景" &&
              v.sceneId == item.sceneId &&
              v.terminalId == item.terminalId &&
              v.equipCode == item.equipCode
          )
        ) {
          return;
        } else {
          let idx = this.tableData.lastIndexOf(
            each => each.terminalId == item.terminalId
          );
          if (idx > -1) {
            //如果存在相同组合的情况,
            this.tableData.splice(idx, 0, item);
          } else {
            this.tableData.push(item);
          }
        }
      });
      for (let i = 0; i < this.tableData.length; i++) {
        if (i == 0) {
          this.tableData[i].show = true;
          this.tableData[i].show2 = true;
        } else {
          this.tableData[i].show =
            this.tableData[i].sceneId == this.tableData[i - 1].sceneId
              ? false
              : true;
          this.tableData[i].show2 =
            this.tableData[i].terminalId == this.tableData[i - 1].terminalId
              ? false
              : true;
        }
      }
      this.tableData.forEach(item => {
        if (item.sceneName == "未知场景") {
          item.show = true;
          item.show2 = true;
        }
      });
      this.selectSceneDialog = false;
      this.sortData();
    },
    //点击确定添加场景类型
    confirmSelect(selectData) {
      if (selectData.length) {
        selectData.map(item => {
          item.newSceneName = "";
          item.newSceneType = "";
          item.dockIds = [];
          item.dockName = "";
        });
        this.tableData = _.cloneDeep(selectData).concat(
          _.cloneDeep(this.tableData)
        );
        var obj = {};
        this.tableData = this.tableData.reduce((item, next) => {
          obj[next.sceneId]
            ? ""
            : (obj[next.sceneId] = true && item.push(next));
          return item;
        }, []);
      }
    },
    //数组排序
    sortData() {
      let result = [];
      let terminalIds = Array.from(
        new Set(
          this.tableData.map(item => {
            return item.terminalId;
          })
        )
      );
      terminalIds.map(item => {
        var arr = this.tableData.filter(v => item == v.terminalId);
        arr.map(each => {
          result.push(each);
        });
      });
      this.addTableDataAttr();
    },
    //从服务单过来需要给每个设备添加一个任务项id
    addTableDataAttr() {
      let tableData = _.cloneDeep(this.tableData);
      let equipList = this.equipList;
      console.log(equipList, "==");
      for (let i = 0; i < tableData.length; i++) {
        let obj = equipList.find(item => {
          return item.equipId == tableData[i].equipId;
        });
        if (obj != undefined && obj) {
          tableData[i].serverInstallDetailId = obj.id;
        }
      }
      this.tableData = tableData;
    },
    //删除任务项
    handleDelete() {
      if (!this.deleteData.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      let tableData = _.cloneDeep(this.tableData);
      let deleteData = _.cloneDeep(this.deleteData);
      this.tableData = tableData.filter(v =>
        deleteData.every(n => {
          return !(
            n.sceneId == v.sceneId &&
            n.terminalId == v.terminalId &&
            n.equipId == v.equipId
          );
        })
      );
      this.deleteData = [];
      this.$refs.multipleTable.clearSelection();
      this.getData([]);
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
    },
    /**********从服务单详情跳转过来的方式***********/
    //获取场景类型
    async queryHasEquipList() {
      try {
        this.loading = true;
        let res = await getSceneEquip({
          corpId: this.queryId,
          equipIdList: this.equipList.map(item => item.equipId)
        });
        let queryTableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        console.log(queryTableData, "queryTableData===");
        if (queryTableData.length) {
          this.handleData(_.cloneDeep(queryTableData));
        } else {
          this.queryTableData = [];
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //处理返回的数据结构
    handleData(data) {
      let queryTableData = data;
      let result = [];
      let tableArr = [];
      //将三维数组合并内容转化为二维数组
      queryTableData.map(item => {
        if (item.terminalList && item.terminalList.length) {
          item.terminalList.map(each => {
            each = {
              sceneAddress: item.sceneAddress,
              sceneId: item.sceneId,
              sceneName: item.sceneName,
              regName: item.regName,
              sceneType: item.sceneType,
              sceneTypeName: item.sceneTypeName,
              vehicleId: item.vehicleId,
              vehicleColor: item.vehicleColor,
              vehicleColorName: item.vehicleColorName,
              equipList: each.equipList,
              realNo: each.realNo,
              isPrivate: each.isPrivate,
              combinationName: each.secondClassName,
              simId: each.simId,
              stopDate: each.stopDate,
              stopDateStr: each.stopDateStr,
              stopDateTimeStamp: each.stopDateTimeStamp,
              stopReason: each.stopReason,
              terminalId: each.terminalId,
              terStatus: each.terStatus,
              commids: each.commids
            };
            result.push(each);
          });
        }
      });
      //将二维数组合并内容转化为一维数组
      result.map(item => {
        if (item.equipList && item.equipList.length) {
          item.equipList.map(each => {
            each = {
              ...item,
              equipId: each.equipId,
              firstClassId: each.firstClassId,
              firstClassName: each.firstClassName,
              fixEndDateStr: each.fixEndDateStr,
              fixStartDate: each.fixStartDate,
              fixStartDateStr: each.fixStartDateStr,
              lifeCycle: each.lifeCycle,
              secondClassId: each.secondClassId,
              secondClassName: each.secondClassName,
              thirdClassId: each.thirdClassId,
              thirdClassName: each.thirdClassName,
              tmId: each.tmId,
              equipCode: each.equipCode,
              virtualType: each.virtualType,
              equipmentType: item.virtualType,
              virtualTypeName: each.virtualTypeName,
              installType: each.installType,
              installTypeName: each.installTypeName
            };
            each.checked1 = true;
            each.checked2 = true;
            each.checked3 = true;
            if (each.equipId) {
              tableArr.push(each);
            }
          });
        }
      });
      // 控制安装组合和已安装设备的显示隐藏
      for (let i = 0; i < tableArr.length; i++) {
        if (i == 0) {
          tableArr[i].isShow1 = true;
          tableArr[i].isShow2 = true;
        } else {
          if (tableArr[i].sceneId == tableArr[i - 1].sceneId) {
            tableArr[i].isShow1 = false;
          } else {
            tableArr[i].isShow1 = true;
          }
          if (tableArr[i].terminalId == tableArr[i - 1].terminalId) {
            tableArr[i].isShow2 = false;
          } else {
            tableArr[i].isShow2 = true;
          }
        }
      }
      this.queryTableData = tableArr;
      this.getData(_.cloneDeep(tableArr));
      // this.tableData = ;
    },
    //校验工作中的任务项
    async checkCreateTaskReq() {
      try {
        this.loading = true;
        let sceneName = this.tableData.map(item => {
          return item.sceneName;
        });
        let res = await checkCreateTask({
          corpId: this.baseInfoForm.corpId,
          sceneName,
          workSecondClass: this.workSecondClass,
          terminalIdList: this.tableData.map(item => {
            return item.terminalId;
          })
        });
        this.unSceneName = res.data.sceneName;
        let arrJoin = _.cloneDeep(this.unSceneName).join(",");
        let filterSceneName = [
          ...new Set(
            _.cloneDeep(this.tableData).map(item => {
              return item.sceneName;
            })
          )
        ];
        if (this.unSceneName.length) {
          if (this.unSceneName.length < filterSceneName.length) {
            this.loading = false;
            let str =
              "场景" + arrJoin + "正在作业中，是否确定过滤掉【作业中】的场景？";
            this.$confirm(str, "确认提交", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                this.createTaskReq();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消"
                });
              });
          }
          if (this.unSceneName.length == filterSceneName.length) {
            this.loading = false;
            this.$message.warning(`${arrJoin}正在作业中，没有可创建的任务项`);
            return;
          }
        } else {
          this.createTaskReq();
        }
      } catch (error) {
        printError(error);
      }
    }
  }
};
